import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import styles from './infoCard.module.scss'

const InfoCard = ({ image, title, description, homepage }) => {
  // Change the size of cards on the homepage.
  let cardSize = homepage
    ? {
      maxWidth: '27rem',
      minHeight: '52rem'
    }
    : {
      maxWidth: '34rem',
      minHeight: '52rem'
    }
  const Image = Object.prototype.toString.apply(image) === '[object String]'
    ? <img
      src={ image }
      title={ title }
      alt={ title }
      className={ styles.card__override_img }
      width="128"
      height="128"
    />
    : <Img
      fixed={ image }
      title={ title }
      alt={ title }
      className={ styles.card__override_img }
    />

  return (
    <div className={ styles.card } style={ cardSize }>
      {Image}
      <h3 className={ styles.card__override_h3 }>
        { title }
      </h3>
      <p>{ description }</p>
    </div>
  )
}

InfoCard.defaultProps = {
  homepage: false,
}

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  homepage: PropTypes.bool,
}

export default InfoCard
