import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import heroBottomRight from '../../../images/bottom-right-hero.png'

import styles from './homehero.module.scss'

const HomeHero = ({ leadLine, heroLine, heroPoints }) => (
  <StaticQuery
    query={graphql`
      query HomeHero {
        logo: file(relativePath: { eq: "top-left-hero.png" }) {
          childImageSharp {
            fixed(width: 530, height: 230) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        hero: file(relativePath: { eq: "Motorway-Alt.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => (
      <div className={ styles.hero }>
        <div className={ styles.hero__offset }>
          <h3>{ leadLine }</h3>
          <h1>{ heroLine }</h1>
          <p>All Our Lawyers Provide:</p>
          <ul>
            {
              heroPoints.map((point, index) => {
                return (
                  <li key={ index }>
                    { point }
                  </li>
                )
              })
            }
          </ul>
          <Link
            to="/contact-us"
            className={ styles.hero__contactButton }
          >
            Contact Us Now
          </Link>
        </div>
        <div className={ styles.hero__filter }></div>
        <Img
          className={ styles.hero__mobile_resize }
          fluid={ data.hero.childImageSharp.fluid }
        />
        <div className={ styles.hero__fixed_top_left }>
          <Link
            to="/"
            title="Return home"
          >
            <Img
              fixed={ data.logo.childImageSharp.fixed }
              alt="Motor Injury Help Line"
            />
          </Link>
        </div>
        <img
          className={ styles.hero__fixed_bottom_right }
          width="670"
          height="220"
          src={heroBottomRight}
        />
      </div>
    )}
  />
)

HomeHero.propTypes = {
  leadLine: PropTypes.string,
  heroLine: PropTypes.string.isRequired,
  heroPoints: PropTypes.array,
}

export default HomeHero
