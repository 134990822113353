import React from 'react'
import PropTypes from 'prop-types'

import Navigation from '../navigation'
import Footer from '../footer'

import styles from './layout.modules.scss'

const Layout = ({ children }) => (
  <div className={ styles.body }>
    <Navigation />
    <div>
      { children }
    </div>
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
