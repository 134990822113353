import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import styles from './newsCard.module.scss'

const NewsCard = ({ title, content, url }) => {
  let excerpt = content.replace(/[>*_#-]/gi, '')
  excerpt = excerpt.substring(0, 180)

  return <div className={ styles.newsCard }>
    <h4>{ title }</h4>
    <hr />
    <p>{ excerpt } [...]</p>
    <Link to={ url }>Read More</Link>
  </div>
}

NewsCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default NewsCard
