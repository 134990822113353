/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import styles from './contact.module.scss'

class Contact extends React.Component {
  render () {
    return (
      <StaticQuery
        query={graphql`
          query ContactComponent {
            contactSettings: allContentYaml(filter: {
              type: { eq: "GlobalSettings" }
            }) {
              edges {
                node {
                  contactTitle
                }
              }
            }
          }
        `}
        render={data => {
          const settings = data.contactSettings.edges[0].node

          return (
            <div className={ styles.contact }>
              <h2>{ this.props.optionalTitle || settings.contactTitle }</h2>
              <div className={ styles.contact__flex }>
                <div>
                  <h4>Call Us</h4>
                  <p>You can call us any day of the week, 24 hours a day on <a href="tel:1300 206 952">1300 206 952</a></p>
                </div>
                <div>
                  <h4>Email Us</h4>
                  <p>Don't want to call? You can send us an e-mail at <a href="mailto:info@motorinjuryhelpline.com.au">info@motorinjuryhelpline.com.au</a></p>
                </div>
              </div>
            </div>
          )
        }}
      />
    )
  }
}

Contact.propTypes = {
  optionalTitle: PropTypes.string,
}

export default Contact
